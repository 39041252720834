<template>

<app-panel v-on:close="$emit('close')" :theme="(is.refunded) ? 'green' : 'default'">
		
	<app-panel-title text="Request Refund" v-if="!is.refunded" />

	<app-panel-text v-if="!is.refunded">

			You are able to request a refund for <b>{{ item.name }}</b><template v-if="item.refundableDate"> until <b>{{ item.refundableDate | localDate('MM/DD/YYYY') }}</b></template>. 
			You will receive your refund within 2-5 working days.

	</app-panel-text>

	<app-panel-text v-if="is.refunded">

			Your refund request has been processed and you should receive your refund within 2-5 working days.

	</app-panel-text>

	<app-panel-content>

		<app-button v-if="!is.refunded" text="Confirm" :loading="is.loading" v-on:click="onConfirmClick" />
		<app-button v-if="is.refunded" text="Close" theme="green" v-on:click="$emit('close')" />

	</app-panel-content>

</app-panel>

</template>

<script>

export default {

	props: ['item'],

	data: function() {

		return {
			is: {
				loading: false,
				error: false,
				refunded: false
			}
		}

	},

	created: function() {

		this.$pubsub.$emit('offset', true)

	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	methods: {

		onConfirmClick: function() {

			this.is.loading = true

			this.$api.post(['convention/registration/purchase', this.$route.params.id, 'refund'], {
				item: this.item.id
			}).then(function() {

				this.is.loading = false
				this.is.refunded = true
				
				this.$emit('refunded')

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.connect {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0px;
}

.connect-avatar {
	width: 128px;
	height: 128px;
	border-radius: 50%;
	background-size: cover;
	background-position: 50% 50%;
	margin-bottom: 10px;
}

.connect-username {
	font-size: 20px;
	letter-spacing: 1px;
}

</style>
